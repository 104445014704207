export const routes = {
  admin: {
    dashboard: '/admin',
    users: {
      list: '/admin/users',
      new: '/admin/users/new',
      edit: '/admin/users/:id',
    },
    translations: {
      list: '/admin/translations',
    },
    profile: '/admin/profile',
    profileSettings: '/admin/profile?activeTab=2',
    settings: '/admin/settings',
    employees: {
      list: '/admin/employees',
      new: '/admin/employees/new',
      edit: '/admin/employees/:id',
    },
    companies: {
      list: '/admin/companies',
      new: '/admin/companies/new',
      edit: '/admin/companies/:id',
    },
    travelers: {
      list: '/admin/travelers',
      new: '/admin/travelers/new',
      edit: '/admin/travelers/:id',
    },
    travelerManagers: {
      new: '/admin/traveler-managers/new',
      edit: '/admin/traveler-managers/:id',
    },
    enquiries: {
      list: '/admin/enquiries',
      edit: '/admin/enquiries/:id',
    },
    quotes: {
      list: '/admin/quotes',
      edit: '/admin/quotes/:id',
    },
    airports: {
      list: '/admin/airports',
    },
    airlines: {
      list: '/admin/airlines',
    },
  },
  auth: {
    login: '/login',
    registrationConfirmation: '/registration-confirmation/:token',
    deletionConfirmation: '/deletion-confirmation/:token',
    passwordReset: '/password-reset',
    passwordResetConfirmation: '/password-reset/:token',
  },
  homepage: '/',
};
