export const apiRoutes = {
  auth: {
    refreshToken: 'auth/refresh-token',
    email: {
      login: 'auth/email/login',
      registrationConfirmation: 'auth/email/registration-confirmation',
      passwordChange: 'auth/email/password-change',
      passwordResetRequest: 'auth/email/password-reset-request',
      passwordReset: 'auth/email/password-reset',
    },
    google: {
      login: 'auth/google/login',
    },
  },
  translations: {
    list: 'translations',
    update: 'translations/:id',
  },
  languages: {
    locale: 'languages/locale/:locale',
    options: 'languages/options',
  },
  users: {
    get: 'users/:id',
    me: 'users/me',
    list: 'users',
    create: 'users',
    update: 'users/:id',
    delete: 'users/:id',
    selfDeleteRequest: 'users/self-delete-request',
    selfDelete: 'users/self-delete/:token',
    bulkDelete: 'users/bulk-delete',
  },
  userTokens: {
    get: 'user-tokens/:token',
  },
  userSettings: {
    updateSelf: 'user-settings/update-self',
    getSelf: 'user-settings/get-self',
    getLayoutOptions: 'user-settings/options/layout',
  },
  profile: {
    updateSelf: 'profile/update-self',
    getSelf: 'profile/get-self',
  },
  roles: {
    options: 'roles/options',
    mappedAccess: 'roles/mapped-access',
  },
  assets: {
    uploadImage: 'assets/upload-image',
    uploadPrivateFile: 'assets/upload-private-file',
    uploadPrivateFiles: 'assets/upload-private-files',
    getAsset: 'assets/owned/:id',
    downloadFile: 'assets/download-file/:id',
    getDownloadableFile: 'assets/get-downloadable-file/:id',
  },
  health: {
    version: 'health/version',
  },
  audit: {
    list: 'audit',
  },
  employees: {
    get: 'employees/:id',
    list: 'employees',
    create: 'employees',
    update: 'employees/:id',
    delete: 'employees/:id',
    rolesOptions: 'employees/role-options',
    typesOptions: 'employees/type-options',
    options: 'employees/options',
    bulkDelete: 'employees/bulk-delete',
  },
  travelers: {
    get: 'travelers/:id',
    list: 'travelers',
    create: 'travelers',
    update: 'travelers/:id',
    delete: 'travelers/:id',
    titleOptions: 'travelers/title-options',
    genderOptions: 'travelers/gender-options',
    typeOptions: 'travelers/type-options',
    wheelchairOptions: 'travelers/wheelchair-options',
    companyTravelersOptions: 'travelers/options/:companyId',
    options: 'travelers/options',
  },
  companies: {
    get: 'companies/:id',
    list: 'companies',
    create: 'companies',
    update: 'companies/:id',
    delete: 'companies/:id',
    options: 'companies/options',
  },
  travelerInfo: {
    sourceOptions: 'traveler-info/source-options',
  },
  travelerManagers: {
    get: 'traveler-managers/:id',
    list: 'traveler-managers',
    create: 'traveler-managers',
    update: 'traveler-managers/:id',
    delete: 'traveler-managers/:id',
    options: 'traveler-managers/options/:companyId',
  },
  orders: {
    get: 'orders/:id',
    list: 'orders',
    listQuotes: 'orders/quotes',
    create: 'orders',
    moveToQuote: 'orders/:id/move-to-quote',
    update: 'orders/:id',
    updateAssignee: 'orders/:id/assignee',
    delete: 'orders/:id',
    archive: 'orders/:id/archive',
  },
  itineraries: {
    get: 'itineraries/:id',
    list: 'itineraries',
    create: 'itineraries',
    update: 'itineraries/:id',
    updatePosition: 'itineraries/position',
    delete: 'itineraries/:id',
  },
  itineraryItems: {
    get: 'itinerary-items/:id',
    list: 'itinerary-items',
    create: 'itinerary-items',
    update: 'itinerary-items/:id',
    updatePosition: 'itinerary-items/position',
    delete: 'itinerary-items/:id',
  },
  airports: {
    get: 'airports/:id',
    list: 'airports',
    delete: 'airports/:id',
    bulkDelete: 'airports/bulk-delete',
    options: 'airports/options',
  },
  airlines: {
    get: 'airlines/:id',
    list: 'airlines',
    create: 'airlines',
    update: 'airlines/:id',
    delete: 'airlines/:id',
    bulkDelete: 'airlines/bulk-delete',
    options: 'airlines/options',
  },
};
